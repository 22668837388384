<template>
    <div class="viewErrors" v-if="state.show">
        <div class="alert" :class="state.err? 'alert-danger':'alert-success'" v-for="(msg,index) in state.msg" :key="index" role="alert">
            {{ msg }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</template>
<script>
import {reactive, computed} from 'vue'
import {useStore} from 'vuex'
export default {
    setup(){
        const store = useStore();
        const state = reactive({
            show: computed(()=> {
                return store.state.error.show
            }),
            err: computed(()=> {
                return store.state.error.error
            }),
            msg: computed(() => {
                return store.state.error.msg
            })
        })
        return {
            state
        }
    }
}
</script>